import React, { useEffect, useState } from "react";
import Navigation from "../../Navigation/navigation";
import PandaBuySignUpLink from "../../PandaBuySignUpLink/PandaBuySignUpLink";
import { useSelector } from "react-redux";
import Footer from "../../Footer/Footer";
import { removeFromWatchList } from "../../../features/watchListSlice";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { AgentName } from "../../../config/config";
const Watchlist = () => {
  const dispatch = useDispatch();
  const watchlist = useSelector((state) => state.watchlist);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    // Calculate total price whenever the watchlist changes
    const calculatedTotalPrice = watchlist.reduce((acc, item) => {
      // Check if 'price' is a valid number before adding it to the total
      const itemPrice = parseFloat(item.price);
      return !isNaN(itemPrice) ? acc + itemPrice : acc;
    }, 0);

    // Round to two decimal places and convert to string for display
    const roundedTotalPrice = calculatedTotalPrice.toFixed(2);

    setTotalPrice(roundedTotalPrice);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [watchlist]);

  const handleRemoveFromWatchlist = (index) => {
    dispatch(removeFromWatchList(index));
  };

  return (
    <div className="container mx-auto">
      <Helmet>
        <title>
          Spreadsheet: Featuring 4200+ {AgentName} || Hagobuy || CNFans ||
          Sugargoo || Superbuy || CSSBuy Fashion Reps with QualityCheck Link.
          Search and filter products by category
        </title>
        <meta
          name="description"
          content="Discover a vast collection of fashion replicas on our web-based spreadsheet. Featuring 3800+ products from Pandabuy, Hagobuy, CNFans, Sugargoo, Superbuy, and CSSBuy. Find quality check links, QC services, Add products to your watchlist for easy tracking."
        />
        <meta
          name="keywords"
          content="Pandabuy shoes, Hagobuy fashion, CNFans clothing, Sugargoo shoes, Superbuy replicas, CSSBuy fashion, fashion replicas, quality check links, taobao agents, weidian agents, watchlist, website spreadsheet"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://allchinabuyspreadsheet.net/Products"
        />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="https://allchinabuyspreadsheet.net/sitemap.xml"
        />
        <script type="application/ld+json" src="/schema.org.jsonld"></script>
      </Helmet>
      <PandaBuySignUpLink />
      <Navigation />
      <div>
        {watchlist.length === 0 ? (
          <p>No items added to watchlist.</p>
        ) : (
          <div className="cardlist ">
            <div className="flex justify-center">
              <h2 className="heroTxt">My Watchlist</h2>
            </div>

            <ul className="flex flex-col gap-y-4 justify-center items-center   ">
              {watchlist.map((item, index) => (
                <div
                  key={index}
                  className="sm:flex max-sm:flex max-sm:flex-col max-sm:mb-64  sm:justify-evenly lg:max-w-[1250px] lg:min-w-[1250px] pt-12 max-lg:gap-x-6"
                  style={{ minHeight: "450px", maxHeight: "450px" }}
                >
                  <div>
                    <img
                      alt="Product"
                      className="sm:min-h-[402px] sm:max-h-[402px] min-w-[350px] max-w-[350px] max-sm:max-h-[262px] max-sm:flex max-sm:justify-center max-sm:items-center"
                      loading="lazy"
                      src={item.pic_url}
                    />
                  </div>

                  <div className="flex flex-col gap-y-8 lg:max-w-[450px] lg:min-w-[450px]  ">
                    <div
                      style={{
                        color: "var(--text-header-color)",
                        textDecoration: "var(--text-header-color)",
                      }}
                      className="flex flex-col justify-between items-center gap-y-4"
                    >
                      <button
                        onClick={() => handleRemoveFromWatchlist(index)}
                        className="underline"
                      >
                        Remove from Watchlist
                      </button>
                    </div>

                    <div className="">
                      <p className="cardPriceTitle ">Title</p>
                      <h2 className="cardTitle max-sm:max-w-[335px] max-sm:text-nowrap max-sm:overflow-hidden">
                        {item.itemName}
                      </h2>
                    </div>

                    <div className="flex justify-between w-full">
                      <div className="">
                        <p className="cardPriceTitle">Dimensions</p>
                        <p className="cardPrice ">{item.dimensions}</p>
                      </div>

                      <div className="">
                        <p className="cardPriceTitle">Sales</p>
                        <p className="cardPrice ">{item.sales}</p>
                      </div>
                    </div>

                    <div className="flex justify-between w-full">
                      <div className="flex flex-col">
                        <p className="cardPriceTitle">Weight</p>
                        {item.weight !== "" ? (
                          <p className="cardPrice">{item.weight} G</p>
                        ) : (
                          <p className="cardPrice"></p>
                        )}
                      </div>

                      <div className="">
                        <p className="cardPriceTitle">Price</p>
                        <p className="cardPrice ">${item.price}</p>
                      </div>
                    </div>

                    <div className="flex flex-col justify-between items-center gap-y-4  ">
                      {/*Adjust value for affilaite provider  */}
                      <a
                        href={item.pandaBuyAffiliateLink}
                        target=".blank"
                        className="btn "
                      >
                        Buy at {AgentName}
                      </a>
                      <a href={item.qcLink} target=".blank" className="btn3">
                        Quality Check
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </ul>
            <div className="flex justify-center gap-x-12 pt-12 max-sm:pt-24">
              <h2 className="heroTxt max-sm:text-4xl">Total Price :</h2>
              <h2 className="heroTxt max-sm:text-4xl">${totalPrice}</h2>
            </div>
          </div>
        )}
      </div>

      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default Watchlist;
